import { createRouter, createWebHashHistory } from 'vue-router';
import RegisterTemplate from '../pages/registration/RegisterTemplate.vue';

import Location from '../pages/templates/Location.vue';
import LocationEdit from '../pages/templates/LocationEdit.vue';
import LocationAdd from '../pages/templates/LocationAdd.vue';

import Restaurant from '../pages/templates/Restaurant.vue';
import RestaurantAdd from '../pages/templates/RestaurantAdd.vue';
import RestaurantEdit from '../pages/templates/RestaurantEdit.vue';

import Templates from '../pages/templates/Templates.vue';
import TemplateList from '../pages/templates/TemplateList.vue';
import TemplateListEdit from '../pages/templates/TemplateListEdit.vue';

import TemplatesEdit from '../pages/templates/TemplatesEdit.vue';
import TemplatesAdd from '../pages/templates/TemplatesAdd.vue';
import ClientPage from '../pages/templates/ClientPage.vue';
import Pdf from '../pages/templates/Pdf.vue';
import ClientPdf from '../pages/templates/ClientPdf.vue';

import Qrcode from '../pages/templates/Qrcode.vue';

import Login from "@lib/frontend/pages/Login.vue";
import Signup from "@lib/frontend/pages/Signup.vue";

import Home from '../pages/Home.vue'
import CruiseLine from '../pages/templates/CruiseLine.vue';
import Converter from '../components/Converter.vue'
import UploadData from '../components/UploadData.vue'
import Msc from '../components/Msc.vue'
import Cunard from '../components/Cunard.vue'
import Oceania from '../components/Oceania.vue'
import Collection from '../components/Collection.vue'







const routes = [
  { path: '/', component: Home, name: 'home' },
  { path: '/clientpage/:id', name: 'clientpage', component: ClientPage, props: true, meta: { navbar: false } },
  { path: '/pdf/:id', name: 'pdf', component: Pdf, props: true, meta: { navbar: false } },
  { path: '/clientpdf/:id', name: 'clientpdf', component: ClientPdf, props: true, meta: { navbar: false } },
  { path: '/qrcode/:id', name: 'qrcode', component: Qrcode, props: true, meta: { navbar: false } },
  { path: '/login', name: 'login', component: Login, props: true, meta: { navbar: false } },
  {
    path: '/signup',
    name: 'signup',
    component: Signup,
    meta: { requiresAuth: false }
  },
  { path: '/shipcalls', name: 'templates', component: TemplateList },
  { path: '/edit-templates/:id', name: 'editlist', component: TemplateListEdit, props: true, meta: { navbar: false } },

  { path: '/register', name: 'Register', component: RegisterTemplate },
  { path: '/cruiselines', name: 'CruiseLine', component: CruiseLine },
  { path: '/locations', name: 'Locations', component: Location },
  { path: '/locations/edit/:id', name: 'Location-Edit', component: LocationEdit },
  { path: '/locations/add', name: 'Location-Add', component: LocationAdd },
  { path: '/restaurant', name: 'Restaurant', component: Restaurant },
  { path: '/restaurant/add', name: 'Restaurant-Add', component: RestaurantAdd },
  { path: '/restaurant/edit/:id', name: 'Restaurant-Edit', component: RestaurantEdit },
  { path: '/template', name: 'Templates', component: Templates },
  { path: '/template/add', name: 'Template-Add', component: TemplatesAdd },
  { path: '/edit-template/:id', name: 'Edit-Template', component: TemplatesEdit },
  { path: '/convert', name: 'Converter', component: Converter },
  { path: '/upload-data', name: 'UploadData', component: UploadData },
  { path: '/msc', name: 'Msc', component: Msc },
  { path: '/cunard', name: 'Cunard', component: Cunard },
  { path: '/oceania', name: 'Oceania', component: Oceania },
  { path: '/collection', name: 'CreateCollectionType', component: Collection },





];

const router = createRouter({
  history: createWebHashHistory(process.env.BASE_URL),
  routes
});

export default router;
