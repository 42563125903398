<template>
  <b-card title="File Upload and Processing" class="p-4 mx-auto mt-4" style="width: 50%;">
    <div>
      <b-form-file @change="handleFileUpload" accept=".xlsx, .xls, .xlsm">
        <template #file-name>
          <div class="d-flex align-items-center">
            <b-icon-file-earmark-spreadsheet-fill class="mr-2"></b-icon-file-earmark-spreadsheet-fill>
            <span v-if="selectedFileName">{{ selectedFileName }}</span>
            <span v-else>Choose a file...</span>
          </div>
        </template>
      </b-form-file>
    </div>

    <div v-if="processedData.length > 0" class="mt-3">
      <b-button @click="exportToExcel" variant="primary">Msc Excel</b-button>
    </div>
    <div v-if="processedDataCunard.length > 0" class="mt-3">
      <b-button @click="exportToExcelCunard" variant="success">Cunard Excel</b-button>
    </div>

    <div class="mt-3">
      <b-button to="/upload-data" variant="success">Send Data to API</b-button>
    </div>
  </b-card>
</template>

<script>
import { defineComponent } from 'vue';
import * as XLSX from 'xlsx';

export default defineComponent({
  name: 'FileUpload',
  data() {
    return {
      processedData: [],
      processedDataCunard: [],
      selectedFileName: '',
    };
  },
  methods: {
    async handleFileUpload(event) {
      const file = event.target.files[0];
      if (file) {
        try {
          const data = await this.readFile(file);
          this.processedData = this.processData(data);
          this.processedDataCunard = this.processDataCunard(data);
          this.selectedFileName = file.name;
        } catch (error) {
          console.error('Error processing file:', error);
        }
      }
    },
    readFile(file) {
      return new Promise((resolve, reject) => {
        const reader = new FileReader();
        reader.onload = (e) => {
          const data = new Uint8Array(e.target.result);
          const workbook = XLSX.read(data, { type: 'array' });
          const sheetName = workbook.SheetNames[0];
          const worksheet = XLSX.utils.sheet_to_json(workbook.Sheets[sheetName], { header: 1 });
          resolve(worksheet);
        };
        reader.onerror = (error) => reject(error);
        reader.readAsArrayBuffer(file);
      });
    },
    processData(data) {
      return data.slice(1).map((row) => ({
        bookingNumber: row[0] ? row[0].toString().trim() : '',
        guestsLastName: row[3] ? row[3].toString().trim() : '',
        firstName: row[4] ? row[4].toString().trim() : '',
        flightNumber: `${row[8] ? row[8].toString().trim() : ''}${row[9] ? row[9].toString().trim() : ''}`,
        language: row[6] ? row[6].toString().trim() : '',
        departureAirport: row[10] ? row[10].toString().trim() : '',
        arrivalAirport: row[13] ? row[13].toString().trim() : '',
        cabinNumber: row[17] ? row[17].toString().trim() : '',
        note: row[20] ? row[20].toString().trim() : '',
        remarks: row[21] ? row[21].toString().trim() : '',
        departureDate: this.convertDateFormat(row[11]), // Convert date format
        arrivalDate: this.convertDateFormat(row[14]), // Convert date format
        departureTime: this.formatExcelTime(row[12]), // Format Excel time
        arrivalTime: this.formatExcelTime(row[15]), // Format Excel time
      })).filter(row => (row.bookingNumber || row.flightNumber) && row.cabinNumber !== '');
    },
    processDataCunard(data) {
      return data.slice(1).map((row) => ({
        bookingNumber: row[8] ? row[8].toString().trim() : '',
        guestsLastName: row[13] ? row[13].toString().trim() : '',
        firstName: row[12] ? row[12].toString().trim() : '',
        flightNumber: `${row[26] ? row[26].toString().trim() : ''}${row[27] ? row[27].toString().trim() : ''}`,
        departureAirport: row[28] ? row[28].toString().trim() : '',
        arrivalAirport: row[31] ? row[31].toString().trim() : '',
        cabinNumber: row[9] ? row[9].toString().trim() : '',
        note: row[35] ? row[35].toString().trim() : '',
        remarks: `${row[36] ? row[36].toString().trim() : ''}${row[37] ? row[37].toString().trim() : ''}`,
        departureDate: this.convertDateFormatCunard(row[29] ? row[29].toString().trim() : ''), // Convert date format
        arrivalDate: this.convertDateFormatCunard(row[32] ? row[32].toString().trim() : ''), // Convert date format
        departureTime: this.convertTimeFormatCunard(row[30] ? row[30].toString().trim() : ''), // Convert time format
        arrivalTime: this.convertTimeFormatCunard(row[33] ? row[33].toString().trim() : ''), // Convert time format
      })).filter(row => (row.bookingNumber || row.flightNumber) && row.cabinNumber !== 'CABIN' && row.cabinNumber !== '');
    },
    convertDateFormat(dateString) {
  if (!dateString) return '';

  // Case 1: If the date is already in `YYYY-MM-DD` format, return it as is
  const isoDateRegex = /^\d{4}-\d{2}-\d{2}$/;
  if (isoDateRegex.test(dateString)) {
    return dateString;
  }

  // Case 2: If the date is in `MM/DD/YYYY` format, convert it to `YYYY-MM-DD`
  const usDateRegex = /^(\d{1,2})\/(\d{1,2})\/(\d{4})$/;
  const match = dateString.match(usDateRegex);

  if (match) {
    const month = match[1].padStart(2, '0');
    const day = match[2].padStart(2, '0');
    const year = match[3];
    return `${year}-${month}-${day}`;
  }

  return ''; // Return empty string if format doesn't match
},

    convertDateFormatCunard(dateString) {
      const dateParts = dateString.split('-');
      if (dateParts.length === 3) {
        const day = dateParts[0].padStart(2, '0');
        const month = this.getMonthNumber(dateParts[1].toUpperCase()).padStart(2, '0');
        const year = '20' + dateParts[2];
        return `${year}-${month}-${day}`;
      }
      return '';
    },
    convertTimeFormatCunard(timeString) {
      const timeParts = timeString.split(':');
      if (timeParts.length === 2) {
        const hours = timeParts[0].padStart(2, '0');
        const minutes = timeParts[1].padStart(2, '0');
        return `${hours}:${minutes}:00`;
      }
      return '';
    },
    getMonthNumber(month) {
      const months = {
        'JAN': '01',
        'FEB': '02',
        'MAR': '03',
        'APR': '04',
        'MAY': '05',
        'JUN': '06',
        'JUL': '07',
        'AUG': '08',
        'SEP': '09',
        'OCT': '10',
        'NOV': '11',
        'DEC': '12'
      };
      return months[month] || '';
    },
    formatExcelTime(timeString) {
  if (!timeString) return '';

  // If time is already in HH:MM:SS format, return as is
  const hhmmssRegex = /^(\d{1,2}):(\d{2}):(\d{2})$/;
  if (hhmmssRegex.test(timeString)) {
    return timeString; // Already in proper format
  }

  // If time is in HH:MM AM/PM format
  const timeRegex = /^(\d{1,2}):(\d{2})\s?(AM|PM)?$/i;
  const match = timeString.match(timeRegex);

  if (match) {
    let hours = parseInt(match[1], 10);
    const minutes = match[2];
    const period = match[3] ? match[3].toUpperCase() : null;

    if (period === 'PM' && hours !== 12) {
      hours += 12;
    } else if (period === 'AM' && hours === 12) {
      hours = 0;
    }

    return `${String(hours).padStart(2, '0')}:${minutes}:00`;
  }

  // If time is in Excel numeric format (e.g., 0.5903)
  if (!isNaN(timeString)) {
    const totalMinutes = Math.round(timeString * 24 * 60);
    const hours = String(Math.floor(totalMinutes / 60)).padStart(2, '0');
    const minutes = String(totalMinutes % 60).padStart(2, '0');
    return `${hours}:${minutes}:00`;
  }

  return ''; // Return empty if the format is not recognized
}
,

    exportToExcel() {
      if (this.processedData.length === 0) {
        console.error('No data to export');
        return;
      }

      const header = [
        "bookingNumber",
        "guestsLastName",
        "firstName",
        "flightNumber",
        "language",
        "departureAirport",
        "arrivalAirport",
        "cabinNumber",
        "note",
        "remarks",
        "departureDate",
        "arrivalDate",
        "departureTime",
        "arrivalTime"
      ];

      const wsData = this.processedData.map(entry => {
        const row = [];
        header.forEach(key => {
          row.push(entry[key] || '');
        });
        return row;
      });

      const ws = XLSX.utils.aoa_to_sheet([header, ...wsData]);
      const wb = XLSX.utils.book_new();
      XLSX.utils.book_append_sheet(wb, ws, 'Sheet1');
      XLSX.writeFile(wb, 'processed_data.xlsx');
    },
    exportToExcelCunard() {
      if (this.processedDataCunard.length === 0) {
        console.error('No data to export');
        return;
      }

      const header = [
        "bookingNumber",
        "guestsLastName",
        "firstName",
        "flightNumber",
        "departureAirport",
        "arrivalAirport",
        "cabinNumber",
        "note",
        "remarks",
        "departureDate",
        "arrivalDate",
        "departureTime",
        "arrivalTime"
      ];

      const wsData = this.processedDataCunard.map(entry => {
        const row = [];
        header.forEach(key => {
          row.push(entry[key] || '');
        });
        return row;
      });

      const ws = XLSX.utils.aoa_to_sheet([header, ...wsData]);
      const wb = XLSX.utils.book_new();
      XLSX.utils.book_append_sheet(wb, ws, 'Sheet1');
      XLSX.writeFile(wb, 'Cunard_data.xlsx');
    },
  },
});
</script>



<style scoped>
.mx-auto {
  margin-left: auto;
  margin-right: auto;
}
</style>
