<template>
    <form v-on:submit.prevent.stop="doSignup">
      <div class="form-group">
        <input type="text" class="form-control" id="username" v-model="attributes.username"
               placeholder="Enter your email" :disabled="loading">
      </div>
  
      <div class="form-group">
        <input type="text" class="form-control" id="email" v-model="attributes.email"
               placeholder="Confirm your email" :disabled="loading">
      </div>
  
      <div class="form-group">
        <input type="password" class="form-control" id="password" v-model="attributes.password"
               placeholder="Enter your password" :disabled="loading">
      </div>
  
      <div class="form-group">
        <input type="password" class="form-control" id="confirmPassword" v-model="attributes.confirmPassword"
               placeholder="Confirm your password" :disabled="loading">
      </div>
  
      <button type="submit" class="btn btn-primary btn-block" :disabled="loading">
        Sign Up
      </button>
    </form>
  
    <div class="mt-3">
      <p class="error">{{ errorMessage }}</p>
    </div>
  
    <div class="mt-2 text-center">
      <p>Already have an account?
        <b-button @click="toggleForm" variant="link">
          Login
        </b-button>
      </p>
    </div>
  </template>
  
  <script>
  import { inject } from "vue";
  
  export default {
    data() {
      return {
        loading: false,
        attributes: {
          username: '',
          email: '',
          password: '',
          confirmPassword: '',
        },
        errorMessage: ''
      };
    },
    setup() {
      const { loginJwt } = inject('user');
      return { loginJwt };
    },
    methods: {
      async doSignup() {
        if (this.attributes.password !== this.attributes.confirmPassword) {
          this.errorMessage = "Passwords do not match!";
          return;
        }
  
        this.loading = true;
        try {
          const response = await this.$xhr.post("/api/auth/local/register", {
            username: this.attributes.username,
            email: this.attributes.email,
            password: this.attributes.password,
          });
  
          localStorage.setItem("authToken", response.data.jwt);
          localStorage.setItem("userProfile", JSON.stringify(response.data.user));
  
          this.$bvToast.toast(`Account created! Welcome, ${response.data.user.email}`, {
            title: "Signup successful",
            variant: "success",
            solid: true,
          });
  
          this.$router.push({ name: "home" });
        } catch (error) {
          this.handleAuthError(error);
        } finally {
          this.loading = false;
        }
      },
  
      handleAuthError(error) {
        this.errorMessage = error.response?.data?.error?.message || "Something went wrong";
        this.$bvToast.toast(this.errorMessage, {
          title: "Error",
          variant: "danger",
          solid: true,
        });
      },
  
      toggleForm() {
        this.$router.push({ name: "login" });
      },
    }
  };
  </script>
  
  <style scoped>
  .error {
    color: red;
    margin-top: 10px;
  }
  </style>
  