<template>
  <div class="container">
    <div class="row justify-content-center mt-5">
      <div class="col-md-6">
        <div class="card">
          <div class="card-body">
            <img class="logo mb-4" alt="Vue logo" src="../assets/new.png">
            <Signup />
        </div>
      </div>
    </div>
  </div>
  </div>
</template>

<script>
import Signup from '@lib/frontend/components/Signup.vue'
export default {
  components: {Signup}
}
</script>

<style scoped>
.logo {
    margin: auto;
    display: block;
    width: 100%;
}

.card {
    border-radius: 20px;
    box-shadow: 0px 0px 20px rgba(0, 0, 0, 0.1);
    background-color: orange;
}

.btn-primary {
    background-color: #2196F3;
    border-color: #2196F3;
}

.btn-primary:hover {
    background-color: #0c7cd5;
    border-color: #0c7cd5;
}

.btn-block {
    border-radius: 20px;
}

label {
    font-weight: bold;
}

p.text-center {
    font-size: 14px;
}

.error {
    color: red;
    margin-top: 10px;
}
</style>