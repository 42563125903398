<template>
  <div>
    <b-card title="Embarkation Data" class="p-4 mx-auto mt-4" style="width: 100%;">
      <div class="legend">
        <span class="legend-item bg-success text-white">Landed (L)</span>
        <span class="legend-item bg-light-red">Landed with Delay</span>
        <span class="legend-item bg-dark-red text-white">Cancelled (C)</span>
        <span class="legend-item bg-light-grey">Active (A)</span>
        <span class="legend-item bg-dark-grey text-white">Active with Delay</span>
        <span class="legend-item bg-light-brown">Scheduled (S)</span>
        <b-button class="legend-item" @click="exportData" variant="primary">
          Export to Excel
        </b-button>
      </div>
      <div>

      </div>
      <b-form-group label="Select Date">
        <b-form-datepicker :value="inputDate" @input="handleDateChange"></b-form-datepicker>
      </b-form-group>
      <b-form-group label="Search">
        <b-input v-model="searchQuery"
          placeholder="Search by Booking Number, Guest Name, Flight Number or Cabin Number"></b-input>
      </b-form-group>
      <b-button @click="refreshData" variant="primary">
        <span v-if="loading" class="mr-2">
          <b-spinner small label="Loading..."></b-spinner>
        </span>
        Check Live Data
      </b-button>
      <span v-if="latestUpdatedAt">Last Updated: {{ formatDateTime(latestUpdatedAt) }}</span>
      <b-card>
        <b-card-text>
          <b>Total Guests: {{ totalGuests }}</b> | <b>Checked Guests: {{ totalCheckedGuests }}</b> | <b>OWN AIR: {{
            totalOwnAir }}</b>
        </b-card-text>
      </b-card>
      <template v-for="(group, key) in filteredData" :key="key">
        <b-card no-body class="mb-1">
          <b-card-header header-tag="header" class="p-1" role="tab" :class="headerClass(group.data)">
            <b-button block v-b-toggle="`accordion-${key}`" :variant="buttonVariant(group.data)" class="text-white">
              Flight {{ group.data[0].attributes.flightNumber }},
              <span v-if="group.data[0].attributes.codeShare">
                <span v-for="(codeShare, index) in group.data[0].attributes.codeShare.split(', ')" :key="index">
                  {{ codeShare }}<span v-if="index < group.data[0].attributes.codeShare.split(', ').length - 1">,
                  </span>
                </span>
              </span>
              from {{ group.data[0].attributes.departureAirport }} | {{ group.data[0].attributes.arrivalDate }} | {{
                formatDate(group.data[0].attributes.arrivalTime) }} ⇒ {{
                formatTime(getGateArrivalTime(group.data[0].attributes)) }}
              ({{ calculateArrivalGateDelay(
                group.data[0].attributes.status,
                group.data[0].attributes.actualGateArrival,
                group.data[0].attributes.estimatedGateArrival,
                group.data[0].attributes.arrivalTime,
                group.data[0].attributes.arrivalDate
              ) }} min)

              <span v-if="group.data[0].attributes.arrivalTerminal">
                | Terminal: {{ group.data[0].attributes.arrivalTerminal }} |
                Gate: {{ group.data[0].attributes.arrivalGate }} |
                Belt: {{ group.data[0].attributes.baggage }} |

              </span>
              | Total Guests: {{ group.data.length }} | Checked Guests: {{ checkedCounts[key] }} | OWN AIR: {{
                group.ownAirCount }} | Ass.Req: {{ totalRemarks }}

            </b-button>
            <b-button class="float-right ml-1" @click="addGuest(group)" variant="success">
              <i class="fas fa-user-plus"></i>
            </b-button>
            <b-i :class="group.isEditing ? 'fas fa-save' : 'fas fa-edit'" class="float-right ml-1"
              @click="toggleEditSave(group, key)" style="cursor: pointer;"></b-i>

            <div class="progress mt-2">
              <i class="fas fa-plane" :style="{ left: calculatePlanePosition(group.data[0]) + '%' }"></i>
              <div class="progress-bar" role="progressbar" :style="{ width: calculateProgress(group.data[0]) + '%' }">
              </div>
            </div>
          </b-card-header>
          <b-collapse :id="`accordion-${key}`" accordion="my-accordion" role="tabpanel">
            <b-card-body>
              <div v-if="group.isEditing">
                <b-form-group label="Flight Number">
                  <b-form-input v-model="group.flightNumber"></b-form-input>
                </b-form-group>
                <b-form-group label="Departure Airport">
                  <b-form-input v-model="group.departureAirport"></b-form-input>
                </b-form-group>
                <b-form-group label="Departure Date">
                  <b-form-input v-model="group.departureDate" type="date"></b-form-input>
                </b-form-group>
                <b-form-group label="Arrival Date">
                  <b-form-input v-model="group.arrivalDate" type="date"></b-form-input>
                </b-form-group>
                <b-form-group label="Arrival Time">
                  <b-form-input v-model="group.arrivalTime"></b-form-input>
                </b-form-group>
                <b-form-group label="Estimated Arrival Time">
                  <b-form-input v-model="group.estimatedGateArrival"></b-form-input>
                </b-form-group>
                <b-form-group label="Actual Arrival Time">
                  <b-form-input v-model="group.actualGateArrival"></b-form-input>
                </b-form-group>
                <b-form-group label="Status">
                  <b-form-input v-model="group.status"></b-form-input>
                </b-form-group>
                <b-form-group label="Arrival Terminal">
                  <b-form-input v-model="group.arrivalTerminal"></b-form-input>
                </b-form-group>
                <b-form-group label="Arrival Gate">
                  <b-form-input v-model="group.arrivalGate"></b-form-input>
                </b-form-group>
                <b-form-group label="Baggage">
                  <b-form-input v-model="group.baggage"></b-form-input>
                </b-form-group>
                <b-form-group label="Buses">
                  <b-form-input v-model="group.buses"></b-form-input>
                </b-form-group>
              </div>
              <b-row v-if="!group.isEditing">
                <b-col md="4"
                  v-for="item in group.data.sort((a, b) => a.attributes.guestsLastName.localeCompare(b.attributes.guestsLastName))"
                  :key="item.id">
                  <b-card class="mb-2" :class="{ 'bg-success text-white': item.checked }">
                    <b-card-text>
                      <b-form-checkbox v-model="item.checked"
                        @change="handleCheckboxChange(item, group.data)">Checked</b-form-checkbox>
                      <br>
                      <div v-if="item.isEditing">
                        <b-form-group label="Flight Number">
                          <b-form-input v-model="item.attributes.flightNumber"></b-form-input>
                        </b-form-group>
                        <b-form-group label="Guest Last Name">
                          <b-form-input v-model="item.attributes.guestsLastName"></b-form-input>
                        </b-form-group>
                        <b-form-group label="First Name">
                          <b-form-input v-model="item.attributes.firstName"></b-form-input>
                        </b-form-group>
                        <b-form-group label="Booking Number">
                          <b-form-input v-model="item.attributes.bookingNumber"></b-form-input>
                        </b-form-group>
                        <b-form-group label="Language">
                          <b-form-input v-model="item.attributes.language"></b-form-input>
                        </b-form-group>
                        <b-form-group label="Cabin Number">
                          <b-form-input v-model="item.attributes.cabinNumber"></b-form-input>
                        </b-form-group>
                        <b-form-group label="Note">
                          <b-form-input v-model="item.attributes.note"></b-form-input>
                        </b-form-group>
                        <b-form-group label="Remarks">
                          <b-form-input v-model="item.attributes.remarks"></b-form-input>
                        </b-form-group>
                        <b-form-group label="Additional Info">
                          <b-form-input v-model="item.attributes.additionalInfo"></b-form-input>
                        </b-form-group>
                        <b-button variant="success" size="sm" @click="saveGuestChanges(item)">Save</b-button>
                        <b-button variant="danger" size="sm" @click="toggleEditGuest(item)"
                          class="ml-2">Cancel</b-button>
                      </div>
                      <div v-else>
                        <b>Guest Name:</b> {{ item.attributes.guestsLastName }}, {{ item.attributes.firstName }}<br>
                        <b>Booking Number:</b> {{ item.attributes.bookingNumber }}<br>
                        <b>Language:</b> {{ item.attributes.language }}<br>
                        <b>Cabin Number:</b> {{ item.attributes.cabinNumber }}<br>
                        <b>Note:</b> {{ item.attributes.note }}<br>
                        <b>Remarks:</b> {{ item.attributes.remarks }}<br>
                        <b>Additional Info:</b> {{ item.attributes.additionalInfo }}<br>
                        <b-button variant="outline-primary" size="sm" @click="addAdditionalInfo(item)">+</b-button>
                        <b-button variant="outline-secondary" size="sm" @click="toggleEditGuest(item)" class="ml-2">
                          <i class="fas fa-edit"></i>
                        </b-button>
                      </div>
                    </b-card-text>
                  </b-card>
                </b-col>
              </b-row>
            </b-card-body>
          </b-collapse>
        </b-card>
      </template>
    </b-card>
  </div>
</template>

<script>
import moment from 'moment';
import { prepareAndExportData } from '../helper/GenerateEmbarkExcel.js';


export default {
  data() {
    return {
      embarkations: [],
      checkedCounts: {},
      searchQuery: '',
      inputDate: null,
      loading: false,
      latestUpdatedAt: null,
    };
  },
  computed: {
    groupedData() {
      const sortedEmbarkations = [...this.embarkations].sort((a, b) => {
        const dateA = a.attributes.arrivalDate;
        const timeA = a.attributes.arrivalTime;
        const dateB = b.attributes.arrivalDate;
        const timeB = b.attributes.arrivalTime;

        if (dateA < dateB) return -1;
        if (dateA > dateB) return 1;
        if (timeA < timeB) return -1;
        if (timeA > timeB) return 1;
        return 0;
      });

      const grouped = {};
      sortedEmbarkations.forEach(item => {
        item.checked = item.attributes.guestStatus === 'Checked';
        const key = `${item.attributes.flightNumber}-${item.attributes.arrivalDate}`;
        if (!grouped[key]) grouped[key] = { data: [], ownAirCount: 0 };
        grouped[key].data.push(item);
        if (item.attributes.note === 'OWN AIR') grouped[key].ownAirCount++;
      });

      return grouped;
    },
    filteredData() {
      if (!this.searchQuery) return this.groupedData;

      const query = this.searchQuery.toLowerCase();
      const filteredGroupedData = {};

      for (const key in this.groupedData) {
        const group = this.groupedData[key].data.filter(item => {
          const { bookingNumber, firstName, guestsLastName, cabinNumber, flightNumber } = item.attributes;

          const fields = [bookingNumber, firstName, guestsLastName, flightNumber, cabinNumber];
          const includesQuery = fields.some(field => {
            if (field) {
              return field.toLowerCase().includes(query);
            }
            return false;
          });

          return includesQuery;
        });

        if (group.length) {
          filteredGroupedData[key] = { data: group };
        }
      }

      return filteredGroupedData;
    },
    totalGuests() {
      return this.embarkations.length;
    },
    totalCheckedGuests() {
      return this.embarkations.filter(item => item.checked).length;
    },
    totalOwnAir() {
      return this.embarkations.filter(item => item.attributes.note === 'OWN AIR').length;
    },
    totalRemarks() {
      return this.embarkations.reduce((count, item) => {
        return item.attributes.remarks ? count + 1 : count;
      }, 0);
    }
  },
  created() {
    this.processFlightStatus = this.processFlightStatus.bind(this);
  },

  methods: {
    async addGuest(group) {
      const newGuest = {
        attributes: {
          guestsLastName: '',
          firstName: '',
          bookingNumber: '',
          language: '',
          cabinNumber: '',
          note: '',
          remarks: '',
          additionalInfo: '',
          guestStatus: 'Unchecked',
          flightNumber: group.data[0].attributes.flightNumber,
          arrivalDate: group.data[0].attributes.arrivalDate,
          departureAirport: group.data[0].attributes.departureAirport,
          arrivalAirport: group.data[0].attributes.arrivalAirport,
          departureDate: group.data[0].attributes.departureDate,
          departureTime: group.data[0].attributes.departureTime,
          arrivalTime: group.data[0].attributes.arrivalTime

        },
        checked: false,
        isEditing: true
      };

      const formattedDate = moment(this.inputDate).format('YYYY-MM-DD');

      try {
        const response = await this.$xhr.post(`/api/embarkation${formattedDate}s`, {
          data: newGuest.attributes
        });
        newGuest.id = response.data.data.id;
        group.data.push(newGuest);
        this.updateCheckedCount(group.data);
      } catch (error) {
        console.error('Error adding guest:', error);
      }
    },
    toggleEditGuest(item) {
      this.$set(item, 'isEditing', !item.isEditing);
    },
    async saveGuestChanges(item) {
      const formattedDate = moment(this.inputDate).format('YYYY-MM-DD');
      const updateData = {
        guestsLastName: item.attributes.guestsLastName,
        firstName: item.attributes.firstName,
        bookingNumber: item.attributes.bookingNumber,
        language: item.attributes.language,
        cabinNumber: item.attributes.cabinNumber,
        note: item.attributes.note,
        remarks: item.attributes.remarks,
        additionalInfo: item.attributes.additionalInfo
      };

      try {
        await this.$xhr.put(`/api/embarkation${formattedDate}s/${item.id}`, {
          data: updateData
        });
        item.isEditing = false;
      } catch (error) {
        console.error('Error saving guest changes:', error);
      }
    },
    formatDateTime(datetime) {
      const options = {
        year: 'numeric', month: 'long', day: 'numeric',
        hour: '2-digit', minute: '2-digit', second: '2-digit',
      };
      return new Date(datetime).toLocaleString(undefined, options);
    },
    exportData() {
      prepareAndExportData(this.filteredData);
    },
    calculateArrivalGateDelay(status, actualGateArrival, estimatedGateArrival, arrivalTime, arrivalDate) {
      const arrival = status === 'L' || status === '' ? moment(actualGateArrival) : moment(estimatedGateArrival);
      const scheduledArrival = moment(`${arrivalDate}T${arrivalTime}`);
      const delayInMinutes = arrival.diff(scheduledArrival, 'minutes');
      return delayInMinutes >= 0 ? `+${delayInMinutes}` : `${delayInMinutes}`;
    },
    getGateArrivalTime(attributes) {
      const status = attributes.status;
      return status === 'L' || status === '' ? attributes.actualGateArrival : attributes.estimatedGateArrival;
    },
    headerClass(group) {
      const status = group[0].attributes.status;
      const delay = parseInt(this.calculateArrivalGateDelay(
        status,
        group[0].attributes.actualGateArrival,
        group[0].attributes.estimatedGateArrival,
        group[0].attributes.arrivalTime,
        group[0].attributes.arrivalDate
      ));

      return {
        'bg-success text-white': status === 'L' && delay <= 15,
        'bg-light-red text-white': status === 'L' && delay > 15,
        'bg-dark-red text-white': status === 'C',
        'bg-light-grey text-white': status === 'A',
        'bg-dark-grey text-white': status === 'A' && delay > 15,
        'bg-light-brown': status === 'S'
      };
    },
    buttonVariant(group) {
      const status = group[0].attributes.status;
      const delay = parseInt(this.calculateArrivalGateDelay(
        status,
        group[0].attributes.actualGateArrival,
        group[0].attributes.estimatedGateArrival,
        group[0].attributes.arrivalTime,
        group[0].attributes.arrivalDate
      ));
      if (status === 'L' && delay <= 15) return 'success';
      if (status === 'L' && delay > 15) return 'light-red';
      if (status === 'C') return 'dark-red';
      if (status === 'A') return 'light-grey';
      if (status === 'A' && delay > 15) return 'dark-grey';
      if (status === 'S') return 'light-brown';
      return 'info';
    },
    updateCheckedCount(group) {
      const key = `${group[0].attributes.flightNumber}-${group[0].attributes.arrivalDate}`;
      this.checkedCounts[key] = group.filter(item => item.checked).length;
    },
    async handleCheckboxChange(item, group) {
      const formattedDate = moment(this.inputDate).format('YYYY-MM-DD');

      await this.$xhr.put(`/api/embarkation${formattedDate}s/${item.id}`, {
        data: { guestStatus: item.checked ? 'Checked' : 'Unchecked' }
      });
      this.updateCheckedCount(group);
    },
    async addAdditionalInfo(item) {
      const formattedDate = moment(this.inputDate).format('YYYY-MM-DD');

      const additionalInfo = prompt('Enter additional information:');
      if (additionalInfo !== null) {
        await this.$xhr.put(`/api/embarkation${formattedDate}s/${item.id}`, { data: { additionalInfo } });
        this.$set(item, 'additionalInfo', additionalInfo);
      }
    },
    formatDate(time) {
      return time ? time.slice(0, 5) : '';
    },
    formatTime(dateTime) {
      if (!dateTime) return '';
      return moment(dateTime).format('HH:mm');
    },
    calculateProgress(flight) {
      const { actualRunwayDeparture, estimatedRunwayArrival } = flight.attributes;

      if (!actualRunwayDeparture || !estimatedRunwayArrival) {
        return 0;
      }

      const departureTime = moment(actualRunwayDeparture);
      const arrivalTime = moment(estimatedRunwayArrival);
      const currentTime = moment();

      const totalDuration = arrivalTime.diff(departureTime, 'minutes');
      const elapsed = currentTime.diff(departureTime, 'minutes');

      let progress = (elapsed / totalDuration) * 100;
      return progress > 99 ? 99 : progress;
    },
    calculatePlanePosition(flight) {
      const { actualRunwayDeparture, estimatedRunwayArrival } = flight.attributes;

      if (!actualRunwayDeparture || !estimatedRunwayArrival) {
        return 0;
      }

      const departureTime = moment(actualRunwayDeparture);
      const arrivalTime = moment(estimatedRunwayArrival);
      const currentTime = moment();

      const totalDuration = arrivalTime.diff(departureTime, 'minutes');
      const elapsed = currentTime.diff(departureTime, 'minutes');

      let position = (elapsed / totalDuration) * 100;
      return position > 99 ? 99 : position;
    },
    async fetchRealTimeData() {
      // Filter out flights that are already landed
      const filteredEmbarkations = this.embarkations.filter(e => e.attributes.status !== 'L' && e.attributes.status !== 'C');
      const uniqueFlightNumbers = [...new Set(filteredEmbarkations.map(e => e.attributes.flightNumber))];

      const fetchFlightData = (carrierFsCode, flightNumber, arrivalDate) => {
        return new Promise((resolve, reject) => {
          const arrivalMoment = moment(arrivalDate);
          const [arrivalYear, arrivalMonth, arrivalDay] = [
            arrivalMoment.year(),
            arrivalMoment.month() + 1, // months are 0-based in moment.js
            arrivalMoment.date()
          ];
          const url = `https://api.flightstats.com/flex/flightstatus/rest/v2/jsonp/flight/status/${carrierFsCode}/${flightNumber}/arr/${arrivalYear}/${arrivalMonth}/${arrivalDay}`;
          const params = {
            appId: process.env.VUE_APP_APP_ID,
            appKey: process.env.VUE_APP_APP_KEY,
            utc: true,
            airport: 'HAM',
            codeType: 'FS',
            callback: `flightStatusCallback_${carrierFsCode}_${flightNumber}`
          };

          // Define the callback function in the global context
          window[`flightStatusCallback_${carrierFsCode}_${flightNumber}`] = (data) => {
            resolve(data);
            // Ensure the context of 'this' is correct
            this.processFlightStatus(data);
            delete window[`flightStatusCallback_${carrierFsCode}_${flightNumber}`];
          };

          const script = document.createElement('script');
          script.src = `${url}?${new URLSearchParams(params).toString()}`;
          script.onload = () => document.head.removeChild(script);
          script.onerror = (error) => {
            console.error(`Error loading JSONP script for flight ${carrierFsCode}${flightNumber}:`, error);
            document.head.removeChild(script);
            reject(error);
          };
          document.head.appendChild(script);
        });
      };

      try {
        const allFlightsData = await Promise.all(uniqueFlightNumbers.map(flightNumber => {
          let carrierFsCode = '';
          let flightNum = '';

          // Check if the flight number starts with one alphabet followed by digits
          if (/^[A-Za-z]\d/.test(flightNumber)) {
            const match = flightNumber.match(/^([A-Za-z]\d)(\d+)/); // One alphabet followed by one digit, then rest digits
            carrierFsCode = match ? match[1] : ''; // First alphabet and one digit
            flightNum = match ? match[2] : ''; // Remaining digits
          } else {
            // Default logic: capture one or more alphabets followed by digits
            const match = flightNumber.match(/^([A-Za-z]+)(\d+)/); // One or more alphabets
            carrierFsCode = match ? match[1] : ''; // Alphabets (up to three)
            flightNum = match ? match[2] : ''; // Remaining digits
          }

          const embarkation = filteredEmbarkations.find(e => e.attributes.flightNumber === flightNumber);
          const arrivalDate = embarkation ? embarkation.attributes.arrivalDate : this.inputDate;

          return fetchFlightData(carrierFsCode, flightNum, arrivalDate);
        }));

        console.log('All Flight Status Data:', allFlightsData);
      } catch (error) {
        console.error('Failed to fetch flight data:', error);
      }


    },

    async processFlightStatus(data) {
      if (data.error) {
        console.error('Error fetching flight status data:', data.error);
        return;
      }

      // Process flightStatuses data
      for (const embarkation of this.embarkations) {
        const matchingFlight = data.flightStatuses.find(flight =>
          (flight.carrierFsCode + flight.flightNumber === embarkation.attributes.flightNumber ||
            flight.codeshares.some(codeshare =>
              codeshare.fsCode + codeshare.flightNumber === embarkation.attributes.flightNumber
            )) &&
          moment(flight.arrivalDate.dateLocal).format('YYYY-MM-DD') === moment(embarkation.attributes.arrivalDate).format('YYYY-MM-DD')
        );

        if (matchingFlight) {
          embarkation.realTimeData = {
            status: matchingFlight.status,
            airportResources: matchingFlight.airportResources || {},
            delays: matchingFlight.delays || {},
            operationalTimes: matchingFlight.operationalTimes || {},
            codeshares: matchingFlight.codeshares || []
          };

          // Save updated embarkation data to API
          await this.updateEmbarkation(embarkation);
        }
      }
    }
    ,
    async updateEmbarkation(embarkation) {
      const formattedDate = moment(this.inputDate).format('YYYY-MM-DD');
      const embarkationId = embarkation.id;

      const dataToUpdate = {
        status: embarkation.realTimeData?.status || '',
        departureGateDelayMinutes: embarkation.realTimeData?.delays?.departureGateDelayMinutes || 0,
        arrivalGateDelayMinutes: embarkation.realTimeData?.delays?.arrivalGateDelayMinutes || 0,
        arrivalTerminal: embarkation.realTimeData?.airportResources?.arrivalTerminal || '',
        baggage: embarkation.realTimeData?.airportResources?.baggage || '',
        codeShare: embarkation.realTimeData?.codeshares
          ? embarkation.realTimeData.codeshares.map(codeshare => `${codeshare.fsCode}${codeshare.flightNumber}`).join(', ')
          : ''
      };
      if (!embarkation.realTimeData?.airportResources?.arrivalTerminal) {
        delete dataToUpdate.arrivalTerminal;
      }

      if (!embarkation.realTimeData?.airportResources?.baggage) {
        delete dataToUpdate.baggage;
      }

      const actualRunwayDeparture = embarkation.realTimeData?.operationalTimes?.actualRunwayDeparture?.dateUtc
        || embarkation.realTimeData?.operationalTimes?.actualGateDeparture?.dateUtc;
      if (actualRunwayDeparture) {
        dataToUpdate.actualRunwayDeparture = actualRunwayDeparture;
      }

      const estimatedRunwayArrival = embarkation.realTimeData?.operationalTimes?.estimatedRunwayArrival?.dateUtc
        || embarkation.realTimeData?.operationalTimes?.estimatedGateArrival?.dateUtc;
      if (estimatedRunwayArrival) {
        dataToUpdate.estimatedRunwayArrival = estimatedRunwayArrival;
      }

      const actualGateArrival = embarkation.realTimeData?.operationalTimes?.actualGateArrival?.dateUtc;
      if (actualGateArrival) {
        dataToUpdate.actualGateArrival = actualGateArrival;
      }
      const estimatedGateArrival = embarkation.realTimeData?.operationalTimes?.estimatedGateArrival?.dateUtc;
      if (estimatedGateArrival) {
        dataToUpdate.estimatedGateArrival = estimatedGateArrival;
      }
      this.loading = true;

      try {
        await this.$xhr.put(`/api/embarkation${formattedDate}s/${embarkationId}`, { data: dataToUpdate });
      } catch (error) {
        console.error('Error updating embarkation:', error);
      }
      finally {
        this.loading = false; // Stop loading
      }
    },
    async refreshData() {
      await this.fetchRealTimeData();
      await this.fetchEmbarkations()
    },
    async fetchEmbarkations() {
      if (!this.inputDate) return; // Exit early if no date selected

      const formattedDate = moment(this.inputDate).format('YYYY-MM-DD');
      const response = await this.$xhr.get(`/api/embarkation${formattedDate}s?populate=*`);
      this.embarkations = response.data.data;

      if (this.embarkations.length > 0) {
        this.latestUpdatedAt = this.embarkations[0].attributes.updatedAt;
      }

      this.checkedCounts = {}; // Clear existing checkedCounts
      this.embarkations.forEach(item => {
        const key = `${item.attributes.flightNumber}-${item.attributes.arrivalDate}`;
        if (!this.checkedCounts[key]) this.checkedCounts[key] = 0;
        if (item.attributes.guestStatus === 'Checked') this.checkedCounts[key]++;
      });
    },
    handleDateChange(newDate) {
      this.inputDate = newDate;
      this.fetchEmbarkations();
    },
    toggleEditSave(group, key) {
      if (group.isEditing) {
        this.saveFlightChanges(group, key);
      } else {
        this.editFlight(group, key);
      }
    },
    editFlight(group, key) {
      // Store the initial state of flight details to allow editing
      this.$set(group, 'flightNumber', group.data[0].attributes.flightNumber);
      this.$set(group, 'departureAirport', group.data[0].attributes.departureAirport);
      this.$set(group, 'departureDate', group.data[0].attributes.departureDate);
      this.$set(group, 'arrivalDate', group.data[0].attributes.arrivalDate);
      this.$set(group, 'arrivalTime', group.data[0].attributes.arrivalTime);
      this.$set(group, 'estimatedGateArrival', group.data[0].attributes.estimatedGateArrival);
      this.$set(group, 'actualGateArrival', group.data[0].attributes.actualGateArrival);
      this.$set(group, 'arrivalTerminal', group.data[0].attributes.arrivalTerminal);
      this.$set(group, 'status', group.data[0].attributes.status);
      this.$set(group, 'arrivalGate', group.data[0].attributes.arrivalGate);
      this.$set(group, 'baggage', group.data[0].attributes.baggage);
      this.$set(group, 'buses', group.data[0].attributes.buses);

      group.isEditing = true;
      this.$nextTick(() => {
        this.$root.$emit('bv::toggle::collapse', `accordion-${key}`);
      });
    },
    async saveFlightChanges(group, key) {
      const formattedDate = moment(this.inputDate).format('YYYY-MM-DD');
      const updateData = {
        flightNumber: group.flightNumber,
        departureAirport: group.departureAirport,
        arrivalDate: group.arrivalDate,
        departureDate: group.departureDate,
        arrivalTime: group.arrivalTime,
        estimatedGateArrival: group.estimatedGateArrival,
        actualGateArrival: group.actualGateArrival,
        arrivalTerminal: group.arrivalTerminal,
        status: group.status,
        arrivalGate: group.arrivalGate,
        baggage: group.baggage,
        buses: group.buses
      };

      for (const flight of group.data) {
        try {
          await this.$xhr.put(`/api/embarkation${formattedDate}s/${flight.id}`, {
            data: updateData
          });
          Object.assign(flight.attributes, updateData); // Update the local data to reflect changes
        } catch (error) {
          console.error('Error saving flight changes:', error);
        }
      }

      group.isEditing = false;
      this.$nextTick(() => {
        this.$root.$emit('bv::toggle::collapse', `accordion-${key}`);
      });
    }

  }
};
</script>

<style scoped>
@import "~@fortawesome/fontawesome-free/css/all.min.css";

.legend {
  display: flex;
  flex-wrap: wrap;
  justify-content: center;
  margin-bottom: 10px;
}

.legend-item {
  margin-right: 10px;
  margin-bottom: 5px;
  padding: 5px;
  border-radius: 5px;
  font-weight: bold;
}

.bg-success {
  background-color: #28a745 !important;
}

.bg-light-red {
  background-color: #c84f59 !important;
}

.bg-dark-red {
  background-color: #fa0019 !important;
}

.bg-light-grey {
  background-color: #bfbcbc !important;
}

.bg-dark-grey {
  background-color: #5c5b5b !important;
}

.bg-light-brown {
  background-color: #d2b48c !important;
}

.text-white {
  color: #fff !important;
}

.mx-auto {
  margin-left: auto;
  margin-right: auto;
}

.progress {
  position: relative;
  height: 20px;
  margin-top: 5px;
}

.progress-bar {
  transition: width 0.3s ease-in-out;
  background-color: #e6e36a;
  height: 100%;
}

.fa-plane {
  color: #f10f0f;
  font-size: 16px;
  top: 50%;
  transform: translateY(-50%);
  z-index: 1;
  position: absolute;
}

.float-right {
  line-height: 2;
}

.fa-edit {
  cursor: pointer;
}

.ml-2 {
  margin-left: 0.5rem;
}
</style>
